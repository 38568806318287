import { Color, Group, Path, Point, Matrix } from 'paper';
import { Component } from 'react';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { transformMetersToPixels } from '@dashboard_utils/index';
import { defaultTransformationMatrix } from '../../../consts';
import {
  ICircleFeature,
  IFeatureInfo,
  IGroupFeature,
  IPathFeature,
} from '../../../types';
import { orderLayers } from '../../eventEmitter';
import MapImages from '../../MapImages';
import Paper from '../../Paper';
import { CurrentPickingRouteTask } from '@models/Order';

interface IData {
  tasks: CurrentPickingRouteTask[];
  loading: boolean;
}

interface IProps {
  id: string;
  data: IData;
  floorplan: IncompleteFloorplan;
  mapImages: MapImages;
  paper: Paper;
}

class RoutingLayer extends Component<IProps> {
  private elements: paper.Group[] = [];

  public componentDidMount() {
    this.load();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { data } = this.props;

    if (prevProps.data.loading !== data.loading) {
      this.reload();
    }
  }

  public componentWillUnmount() {
    this.clear();
  }

  public reload() {
    this.clear();

    this.load();
  }

  public clear() {
    this.elements.forEach((e) => e.remove());
    this.elements = [];
  }

  public load() {
    const {
      data,
      floorplan,
      mapImages,
      paper,
    } = this.props;

    paper.scope.activate();

    let last: any;
    data.tasks
      .forEach((task) => {
        if (task.locationX && task.locationY) {
          const center = new Point(
            transformMetersToPixels(
              [task.locationX / 100, task.locationY / 100 || 0],
              floorplan.transformationMatrix || defaultTransformationMatrix,
              floorplan.scale || 1
            )
          );

          const featureInfo: IFeatureInfo = {
            coordinates: [[task.locationX || 0, task.locationY || 0]],
            id: `task_${task.itemId || 'depo'}`,
            title: task.itemName || 'Depo',
            props: {},
            type: 'task',
          };

          const circle1 = new Path.Circle(
            new Point([center.x, center.y]),
            10
          ) as ICircleFeature;
          circle1.fillColor = new Color('white');
          circle1.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
          circle1.featureInfo = featureInfo;

          const circle2 = new Path.Circle(
            new Point([center.x, center.y]),
            20
          ) as ICircleFeature;
          circle2.fillColor = new Color(task.isDepo ? 'blue' : 'red');
          circle2.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
          circle2.featureInfo = featureInfo;

          const elements = [circle2, circle1];
          if (last) {
            const path = new Path() as IPathFeature;
            path.strokeColor = new Color('red');
            path.strokeWidth = 3
            path.strokeScaling = true;
            path.dashArray = [12, 12];
            path.add(last);
            path.add(center);
            path.closePath();
            path.featureInfo = featureInfo;
            path.transform(new Matrix(1, 0, 0, -1, 0, mapImages.height));
            elements.push(path);
          }

          last = center;

          const group = new Group(elements) as IGroupFeature;
          group.featureInfo = featureInfo;

          this.elements.push(group);
        }
      });

    orderLayers();
  }

  public render() {
    return null;
  }
}

export default RoutingLayer;
