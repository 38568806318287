import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { CompleteOrder, FetchCurrentSession } from '@actions/routing';
import { CurrentPickingRoute } from '@models/Order';

interface IState {
  order: boolean;
}
interface IProps {
  router: any;
  language: string;
  pickingTaskId?: any;
  completeOrderLoading: boolean;
  completeOrderComplete: boolean;
  completeOrder: (properties: CompleteOrder) => void;
  quickOrder: () => void;
  fetchCurrentRoute: (props: FetchCurrentSession) => void;
  currentLoading: boolean;
  currentRoute?: CurrentPickingRoute;
}

class Done extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      order: true,
    };

    this.confirm = this.confirm.bind(this);
  }

  public componentDidMount() {
    const { fetchCurrentRoute, router } = this.props;

    fetchCurrentRoute({ pickingSessionId: router.params.pickingSessionId });
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      completeOrderLoading,
      completeOrderComplete,
      currentLoading,
      currentRoute,
      language,
      router,
      quickOrder,
    } = this.props;
    const { order } = this.state;

    if (prevProps.completeOrderLoading !== completeOrderLoading && prevProps.completeOrderLoading && completeOrderComplete) {
      if (order) {
        quickOrder();
      }

      return router.navigate(`/${language}/dashboard/routing`);
    }

    if (prevProps.currentLoading !== currentLoading && prevProps.currentLoading && completeOrderComplete) {
      const hasIncomplete = currentRoute?.tasks.find((t) => !t.isComplete);
      if (hasIncomplete) {
        return router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId}/scan/location`);
      }
    }
  }

  public confirm(order: boolean) {
    const { completeOrder, pickingTaskId, router } = this.props;

    this.setState({ order }, () => {
      completeOrder({
        pickingSessionId: router.params.pickingSessionId || '',
        pickingTaskId: pickingTaskId || '',
      });
    })
  }

  public render() {
    return (
      <Box className="tab-content-inner">
        <Box p={2}>
          <Button variant="contained" onClick={() => this.confirm(true)} fullWidth>
            <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & Quick Order</Typography>
          </Button>

          <Box pt={2}>
            <Button variant="contained" onClick={() => this.confirm(false)} fullWidth>
              <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & End Session</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Done;
