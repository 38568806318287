import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  addOrderToCurrentBasket,
  fetchOrders,
  quickOrder,
  IAddOrderToBasket,
  IFetchOrders,
  fetchCurrentSession,
  fetchCurrentBasket,
  addOrderToNewBasket,
  FetchCurrentSession,
  pickingSessionState,
  suspendOrder,
  SuspendOrder,
} from '@actions/index';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Routing from './Routing';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.routing.orders.loading,
  orders: state.routing.orders.list,
  quickOrderLoading: state.routing.quickOrder.loading,
  quickOrderData: state.routing.quickOrder.order,
  currentBasket: state.routing.currentBasket.basket,
  currentSession: state.routing.currentSession.session,
  currentState: state.routing.currentState.currentState,
  suspendOrderLoading: state.routing.suspendOrder.loading,
  addOrderLoading: state.routing.basketOrders.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentBasket: () => dispatch(fetchCurrentBasket.request()),
  fetchCurrentSession: () => dispatch(fetchCurrentSession.request()),
  fetchOrders: (properties: IFetchOrders) =>
    dispatch(fetchOrders.request(properties)),
  addOrderToCurrentBasket: (properties: IAddOrderToBasket) =>
    dispatch(addOrderToCurrentBasket.request(properties)),
  addOrderToNewBasket: (properties: IAddOrderToBasket) =>
    dispatch(addOrderToNewBasket.request(properties)),
  quickOrder: () => dispatch(quickOrder.request()),
  pickingSessionState: (properties: FetchCurrentSession) =>
      dispatch(pickingSessionState.request(properties)),
  suspendOrder: (properties: SuspendOrder) =>
      dispatch(suspendOrder.request(properties))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));
