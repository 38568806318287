import React, { Component } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import {
  FetchCurrentSession,
  IAutoLocate,
  IValidateItemLocation,
  IValidateItemQuantity,
  StopPickingSession,
} from '@actions/routing';
import LoadingIconButton from '@app/common/LoadingButton/LoadingIconButton';

interface IProps {
  loading: boolean;
  validateLocationLoading: boolean;
  validateQuantityLoading: boolean;
  validateItemLocation: (props: IValidateItemLocation) => void;
  validateItemQuantity: (props: IValidateItemQuantity) => void;
  fetchCurrentRoute: (props: FetchCurrentSession) => void;
  currentLoading: boolean;
  autoLocate: (properties: IAutoLocate) => void;
  autoLocateLoading: boolean;
  stopPickingSessionLoading: boolean;
  stopPickingSessionComplete: boolean;
  stopPickingSession: (properties: StopPickingSession) => void;
  router: any;
  language: string;
  locationId?: string;
}
interface IState {
  interfaceType: 'location' | 'item';
  data?: string;
}

const scanner = (callback: (data: string) => void) => {
  // @ts-ignore
  if (window.MessageInvoker) {
    // @ts-ignore
    window.MessageInvoker.postMessage('scan');
  }

  const interval = setInterval(() => {
    // @ts-ignore
    const data = window.nativeData;

    if (data) {
      // @ts-ignore
      window.nativeData = undefined;

      clearInterval(interval);

      callback(data);
    }
  }, 200);

  setTimeout(() => {
    if (interval) {
      clearInterval(interval);
    }
  }, 120000);
};

const StyledButton = styled(Button)({
  position: 'absolute',
  zIndex: 1,
  bottom: 20,
  left: 40,
  right: 40,
  backgroundColor: 'yellow',
  color: '#333',
  margin: '0 auto',
});


class ScanPlaceholder extends Component<IProps, IState> {
  private timeout?: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);

    this.state = {
      interfaceType: props.router.params.type || 'location',
    };

    this.scan = this.scan.bind(this);
    this.autoLocate = this.autoLocate.bind(this);
    this.home = this.home.bind(this);
    this.viewRoute = this.viewRoute.bind(this);
    this.stopPickingSession = this.stopPickingSession.bind(this);
  }
  
  public componentDidUpdate(prevProps: IProps) {
    const { currentLoading, validateLocationLoading, validateQuantityLoading, language, router } = this.props;
    const { data } = this.state;

    if (
      (prevProps.validateLocationLoading !== validateLocationLoading && prevProps.validateLocationLoading) ||
      (prevProps.validateQuantityLoading !== validateQuantityLoading && prevProps.validateQuantityLoading)
    ) {
      router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId || ''}/scan/${router.params.type}/${data}`);
    }

    if (prevProps.currentLoading !== currentLoading && prevProps.currentLoading) {
      router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId || ''}/map`);
    }
  }

  public componentDidMount() {
    /*
    this.timeout = setTimeout(() => {
      this.scan();
    }, 2500);
    */
  }

  public scan() {
    const { validateItemLocation, validateItemQuantity, locationId, router } = this.props;

    scanner((data: string) => {
      if (data) {
        this.setState({ data }, () => {
          if (router.params.type === 'location') {
            validateItemLocation({
              barcode: data,
              pickingSessionId: router.params.pickingSessionId,
            });
          } else{
            validateItemQuantity({
              barcode: data,
              pickingSessionId: router.params.pickingSessionId,
              locationId: locationId || '',
            });
          }
        });
      }
    });

    this.setState({ data: router.params.type === 'location' ? 'OTA2090904' : '8410921042102' }, () => {
      if (router.params.type === 'location') {
        return validateItemLocation({
          barcode: this.state.data || '',
          pickingSessionId: router.params.pickingSessionId,
        });
      }

      validateItemQuantity({
        barcode: this.state.data || '',
        pickingSessionId: router.params.pickingSessionId,
        locationId: locationId || '',
      });
    });
  }

  public autoLocate(e: any, id: string) {
    const { autoLocate } = this.props;

    e.stopPropagation();

    autoLocate({ basketId: id });
  }

  public componentWillUnmount(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public home() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/routing`);
  }

  public viewRoute() {
    const { fetchCurrentRoute, router } = this.props;

    fetchCurrentRoute({ pickingSessionId: router.params.pickingSessionId });
  }

  public stopPickingSession() {
    const { router, stopPickingSession } = this.props;

    stopPickingSession({
      pickingSessionId: router.params.pickingSessionId || '',
    });
  }

  public render() {
    const { interfaceType } = this.state;
    const { autoLocateLoading, currentLoading, stopPickingSessionLoading } = this.props;

    return (
      <>
        <AppBar position="static" color="transparent">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton onClick={this.home}>
              <HomeIcon />
            </IconButton>
            <Box>
              <LoadingIconButton onClick={this.viewRoute} loading={currentLoading}>
                <MapOutlinedIcon />
              </LoadingIconButton>
              <LoadingIconButton onClick={this.stopPickingSession} loading={stopPickingSessionLoading}>
                <HighlightOffIcon />
              </LoadingIconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component={Button}
          className="tab-content-inner"
          style={{
            position: 'relative',
            backgroundImage: 'url("/assets/scanning.png")',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#F1F1F1',
            backgroundPosition: 'center',
            backgroundSize: '75%, auto',
          }}
          onClick={this.scan}
        >
          <Box
            style={{
              position: 'absolute',
              top: 0,
              width:'100%',
              backgroundColor: 'yellow',
              textAlign: 'center',
              color: '#333',
            }}
            p={4}
          >
            <Typography variant="h2" style={{ fontWeight: 700 }}>Scan {interfaceType === 'location' ? 'Location' : 'Item'}</Typography>
          </Box>
        
          {interfaceType === 'location' && (
            <StyledButton onClick={!autoLocateLoading ? (e: any) => this.autoLocate(e, 'a') : (e: any) => e.stopPropagation()}>
              {autoLocateLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <>
                  <CropFreeIcon fontSize="large" />

                  <Box pl={1}>
                    <Typography variant="inherit">Auto Locate</Typography>
                  </Box>
                </>
              )}
            </StyledButton>
          )}
        </Box>
      </>
    );
  }
}

export default ScanPlaceholder;
