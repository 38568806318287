import React, { useEffect, useRef } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';

import { FullBasket } from '@models/Order';
import Loading from '@app/common/Loading';
import LoadingButton from '@app/common/LoadingButton';
import { CheckoutBasket, StartPickingSession } from '@actions/index';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IProps {
  onClose: () => void;
  language: string;
  showCurrentBasket: () => void;
  loading: boolean;
  checkoutLoading: boolean;
  pickingLoading: boolean;
  pickingSessionId?: string;
  fetchCurrentBasket: () => void;
  checkoutBasket: (properties: CheckoutBasket) => void;
  startPickingSession: (properties: StartPickingSession) => void
  currentBasket?: FullBasket;
}

const CurrentBasketSheet = (props: IProps) => {
  const {
    onClose,
    language,
    currentBasket,
    loading,
    checkoutLoading,
    fetchCurrentBasket,
    pickingLoading,
    pickingSessionId,
    startPickingSession,
    checkoutBasket,
  } =  props;

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [orderConfirmationOpen, setOrderConfirmation] = React.useState(false);
  const prevLoadingRef = useRef(checkoutLoading);
  const prevPickingLoadingRef = useRef(pickingLoading);
  const prevCurrentBasketRef = useRef(currentBasket);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentBasket();

    setInitialLoad(false);
  }, [fetchCurrentBasket]);

  useEffect(() => {
    const prevLoading = prevLoadingRef.current;
    const prevCurrentBasket = prevCurrentBasketRef.current;

    if (prevLoading !== checkoutLoading && prevLoading) {
      startPickingSession({ basketId: prevCurrentBasket?.id || '' });
    }

    prevLoadingRef.current = checkoutLoading;
    prevCurrentBasketRef.current = currentBasket;
  }, [
    checkoutLoading,
    currentBasket,
    startPickingSession,
    prevCurrentBasketRef,
  ]);

  useEffect(() => {
    const prevLoading = prevLoadingRef.current;
    const prevCurrentBasket = prevCurrentBasketRef.current;

    if (prevLoading !== checkoutLoading && prevLoading) {
      startPickingSession({ basketId: prevCurrentBasket?.id || '' });
    }

    prevLoadingRef.current = checkoutLoading;
    prevCurrentBasketRef.current = currentBasket;
  }, [
    checkoutLoading,
    currentBasket,
    startPickingSession,
    prevCurrentBasketRef,
  ]);

  useEffect(() => {
    const prevLoading = prevPickingLoadingRef.current;

    if (prevLoading !== pickingLoading && prevLoading) {
      setOrderConfirmation(false);
  
      onClose();
  
      navigate(`/${language}/dashboard/routing/${pickingSessionId}/scan/location`);
    }

    prevPickingLoadingRef.current = pickingLoading;
  }, [
    navigate,
    pickingLoading,
    prevPickingLoadingRef,
    pickingSessionId,
  ]);

  const displayPickConfirm = () => {
    setOrderConfirmation(true);
  }

  const hidePickConfirm = () => {
    setOrderConfirmation(false);
  }

  const pickingSession = () => {
    checkoutBasket({ basketId: currentBasket?.id || '' });
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={true}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            padding: 2,
            minHeight: '30vh'
          },
        }}
      >
        <Loading loading={initialLoad || loading}>
          <Box display="flex" flexDirection="column" justifyContent="middle" alignItems="center" mb={2}>
            <Typography variant="h6">Current Basket</Typography>
            <Box mt={1}>
              <Typography variant="caption">{currentBasket?.id}</Typography>
            </Box>
          </Box>

          <Box mb={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={!currentBasket}
              onClick={displayPickConfirm}>Pick Order</Button>
          </Box>

          <Box mt={4}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order</StyledTableCell>
                    <StyledTableCell>Due Date</StyledTableCell>
                    <StyledTableCell>Items</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(currentBasket?.orders || []).map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.orderNo}</StyledTableCell>
                      <StyledTableCell>{format(new Date(row.dueDate), 'yyyy-MM-dd HH:mm')}</StyledTableCell>
                      <StyledTableCell>
                        {row.items.map((item) => (
                          <Box mb={1} key={item.id}>
                            <Typography variant="body1">{item.name}</Typography>
                            <Typography variant="caption">{item.description}</Typography><br />
                            <b>Qt {item.quantity}/{item.quantityAvailable}</b>
                          </Box>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Loading>
      </Drawer>

      <Dialog
        open={orderConfirmationOpen}
        onClose={hidePickConfirm}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            pickingSession();
          },
        }}
      >
        <DialogTitle>You are about to pick the current basket</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Typography variant="body1">
              Are you certain you wish to proceed?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary" type="submit" loading={checkoutLoading || pickingLoading}>Confirm Picking</LoadingButton>
          <Button variant="contained" color="error" onClick={hidePickConfirm}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CurrentBasketSheet;
