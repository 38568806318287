import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '@app/common/Loading';
import { IFetchOrderItems } from '@actions/routing';
import { OrderWithItems } from '@models/Order';
import { IconButton } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IProps {
  router: any;
  language: string;
  fetchOrderItems: (properties: IFetchOrderItems) => void;
  loading: boolean;
  order?: OrderWithItems;
}

const Order = (props: IProps) => {
  const { language, loading, order, fetchOrderItems, router } = props;
  const { orderNo, dueDate, items } = order || {};

  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = React.useState(true);

  useEffect(() => {
    fetchOrderItems({ orderId: router.params.orderId });

    setInitialLoad(false);
  }, [fetchOrderItems]);

  
  const home = () => {
    router.navigate(`/${language}/dashboard/routing`);
  }

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Box className="tab-content-inner">
      <AppBar position="static" color="transparent">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton onClick={home}>
            <HomeIcon />
          </IconButton>
          {window.history.length > 1 && (
            <Button color="inherit" onClick={onBack} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box p={2} mt={4}>
        <Loading loading={initialLoad || loading}>
          <Typography variant="h4">{orderNo}</Typography>
          <Typography variant="subtitle1">{format(new Date(dueDate || 0), 'yyyy-MM-dd HH:mm')}</Typography>
          <Box mt={4}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                    <StyledTableCell>xyz</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(items || []).map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>
                        <StarBorderIcon />
                      </StyledTableCell>
                      <StyledTableCell>{row.quantity}</StyledTableCell>
                      <StyledTableCell>{row.location?.posx || 0},{row.location?.posy || 0},{row.location?.posz || 0}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Loading>
      </Box>
    </Box>
  );
};

export default Order;
