import { connect } from 'react-redux';

import { IAppState } from '@reducers/index';
import { getMapById } from '@selectors/app';
import { getFloorplanById } from '@selectors/floorplans';
import RoutingLayer from './Routing';

interface IProps {
  id: string;
  filterId?: string;
}

const mapStateToProps = (state: IAppState, props: IProps) => {
  const { id } = props;

  const map = getMapById(state, id);

  return {
    mapImages: map.mapImages,
    paper: map.paper,
    floorplan: getFloorplanById(state, map.floorplanId)!,
    data: {
      tasks: state.routing.currentRoute.currentRoute?.tasks || [],
      loading: state.routing.currentRoute.loading,
    },
  };
};

export default connect(mapStateToProps)(RoutingLayer);
