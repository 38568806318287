import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmValidateItemLocation,
  confirmValidateItemQuantity,
  IConfirmValidateItemLocation,
  IConfirmValidateItemQuantity,
  StopPickingSession,
  stopPickingSession,
  ValidateToteComplete,
  validateToteComplete,
} from '@actions/routing';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import ScanResult from './ScanResult';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  confirmLocationLoading: state.routing.confirmValidateItemLocation.loading,
  confirmQuantityLoading: state.routing.confirmValidateItemQuantity.loading,
  loading: state.routing.confirmValidateItemLocation.loading ||
  state.routing.confirmValidateItemQuantity.loading,
  confirmedByAPI: state.routing.confirmValidateItemLocation.confirmed,
  confirmedByAPI2: state.routing.confirmValidateItemQuantity.confirmed,
  locationBarcode: state.routing.validateItemLocation.barcode,
  pickingTaskId: state.routing.validateItemLocation.pickingTaskId,
  itemName: state.routing.validateItemQuantity.itemName,
  requiredQuantity: state.routing.validateItemQuantity.requiredQuantity,
  stopPickingSessionLoading: state.routing.stopPickingSession.loading,
  stopPickingSessionComplete: state.routing.stopPickingSession.complete,
  validateToteCompleteLoading: state.routing.validateToteComplete.loading,
  validateToteCompleteComplete: state.routing.validateToteComplete.complete,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirmValidateItemLocation: (properties: IConfirmValidateItemLocation) =>
    dispatch(confirmValidateItemLocation.request(properties)),
  confirmValidateItemQuantity: (properties: IConfirmValidateItemQuantity) =>
    dispatch(confirmValidateItemQuantity.request(properties)),
  stopPickingSession: (properties: StopPickingSession) =>
    dispatch(stopPickingSession.request(properties)),
  validateToteComplete: (properties: ValidateToteComplete) =>
    dispatch(validateToteComplete.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanResult));
