import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { addOrderToNewBasket, IAddOrderToBasket } from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import OrderSheet from './OrderSheet';

const mapStateToProps = (state: IAppState) => ({
  loading: state.routing.quickOrder.loading,
  order: state.routing.quickOrder.order,
  ordersLoading: state.routing.basketOrders.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addOrderToNewBasket: (properties: IAddOrderToBasket) =>
    dispatch(addOrderToNewBasket.request(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderSheet));
