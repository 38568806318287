import React, { FunctionComponent, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loading from '@app/common/Loading';
import RoutingTabGridContainer from './RoutingTabGridContainer';
import ScanPlaceholder from './ScanPlaceholder';
import ScanResult from './ScanResult';
import Done from './Done';
import Order from './Order';
import Basket from './Basket';

interface IProps {
  language: string;
}

const RouterComp: FunctionComponent<IProps> = ({ language }: IProps) => (
  <Suspense fallback={<Loading loading />}>
    <Routes>
      <Route
        path="/:pickingSessionId/scan/:type/:data"
        element={<ScanResult />}
      />
      <Route
        path="/:pickingSessionId/scan/:type"
        element={<ScanPlaceholder />}
      />
      <Route
        path="/:pickingSessionId/done"
        element={<Done />}
      />
      <Route
        path="/:pickingSessionId/map"
        element={<RoutingTabGridContainer />}
      />
      <Route
        path="/:orderId"
        element={<Order />}
      />
      <Route
        path="/basket"
        element={<Basket />}
      />
      <Route
        path="/"
        element={<RoutingTabGridContainer />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${language}/dashboard/routing`} replace />}
      />
    </Routes>
  </Suspense>
);

export default RouterComp;
